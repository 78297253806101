// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akademia-js": () => import("./../../../src/pages/akademia.js" /* webpackChunkName: "component---src-pages-akademia-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-dla-partnerow-js": () => import("./../../../src/pages/dla-partnerow.js" /* webpackChunkName: "component---src-pages-dla-partnerow-js" */),
  "component---src-pages-dla-rodzica-js": () => import("./../../../src/pages/dla-rodzica.js" /* webpackChunkName: "component---src-pages-dla-rodzica-js" */),
  "component---src-pages-dla-ucznia-js": () => import("./../../../src/pages/dla-ucznia.js" /* webpackChunkName: "component---src-pages-dla-ucznia-js" */),
  "component---src-pages-fundusz-js": () => import("./../../../src/pages/fundusz.js" /* webpackChunkName: "component---src-pages-fundusz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-pytania-i-odpowiedzi-js": () => import("./../../../src/pages/pytania-i-odpowiedzi.js" /* webpackChunkName: "component---src-pages-pytania-i-odpowiedzi-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

